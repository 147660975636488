.ets__contactus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ets__contactus_g1 {
  /* background-color: #f3efe5; */
  background-image: url("../../assets/BG_1.jpg");
  width: 100%;
  height: 250px;

}

.ets__contactus-heading {
  width: 100%;
  text-align: left;
  margin-left: 15rem;
  margin-right: 15rem;
}
.ets__contactus-heading h1 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 35px;
    line-height: 65px;
    letter-spacing: -0.04em;
    color: #1190a8;
}

.ets__contactus-para p {
  font-family: var(--font-family);
  color: #303030;
  font-weight: 100;
  font-size: 23px;
  line-height: 35px;
  letter-spacing: -0.02em;
  margin-left: 15rem;
  margin-right: 15rem;
  margin-bottom: 15rem;
}

.ets__contactus-symbol{
  margin-left: 15rem;
  margin-top: 1rem;
  font-size: 30px;
}

.ets__contactus-symbol img{
  width:  25px;
  height: 25px;
  
}

/* @media screen and (max-width: 990px) {
  .ets__contactus-para {
    margin-left: 20rem;
    margin-right: 5rem;
    margin-bottom: 2rem;
  }
} */
/* @media screen and (max-width: 990px) {
  .ets__contactus-para {
    margin-left: 20rem;
    margin-right: 5rem;
    margin-bottom: 2rem;
  }
} */
/* @media screen and (max-width: 700px) {
  .ets__contactus-heading h1 {
    font-size: 46px;
    line-height: 52px;
  }
  .ets__contactus-para {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 2rem;
  }
} */
@media screen and (max-width: 550px) {
  .ets__contactus-heading {
    width: 100%;
    text-align: left;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  
  .ets__contactus-heading h1 {
    font-size: 34px;
    line-height: 42px; 
  }

  .ets__contactus-heading h2 {
    font-size: 34px;
    line-height: 42px; 
  }

  .ets__contactus-para p {
    margin-bottom: 1rem;
    line-height: 20px;
    font-weight: 100;
    font-size: 15px;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .ets__contactus-symbol{
    margin-left: 2rem;
    font-size: 25px;
  }
}